import "./marketinhome.scss";
import { Button } from "react-bootstrap";
import { useEffect } from "react";
import { useState } from "react";
import { getSummary } from "../../utils/Api";
import { RotatingLines } from "react-loader-spinner";
import { Link, NavLink } from "react-router-dom";
import { UserContext } from "../../contexts/providers/user";
import { useContext } from "react";
import ArrowDownwardIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import ArrowUpwardIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

const homeCurrencies = ["AAVE", "BTC", "ETH", "ADA", "BNB", "TRX"];

export default function MarketInHomeMobile() {
  const { state } = useContext(UserContext);
  const [summaryData, setSummaryData] = useState(null);

  const errorCatch = (error) => { };

  useEffect(() => {
    getSummary(setSummaryData, errorCatch);
  }, []);
  // console.log(summaryData)
  if (!summaryData) {
    return (
      <div className="container-fluid rotating-loader-lines">
        <div className="row justify-content-center">
          <div className="col text-center">
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="35"
              visible={true}
            />
          </div>
        </div>
      </div>
    );
  }


  return (
    <>
      {/* <div className="container-fluid priceBox">
        {summaryData && <NarrowPriceBox name={summaryData} />}
      </div> */}
      <div className="row">
        <div className="col-2">

        </div>
        <div className="col-2 text-end me-2">
          <h6 className="top-market-home">جفت ارز</h6>
        </div>
        <div className="col-3 text-end">
          <h6 className="top-market-home">آخرین قیمت</h6>
        </div>
        <div className="col-3 text-end p-0 m-0">
          <h6 className="top-market-home">تغییرات  (24h)</h6>
        </div>
      </div>
      {summaryData
        .filter((item) => {
          return homeCurrencies.includes(item.base_currency);
        })
        .sort((a, b) => a.base_currency.localeCompare(b.base_currency))
        .map((row) => (
          <div className="row">
            <div className="col-12">
              <NavLink
                as={Link}
                to={`/trade/${row.trading_pairs}`}
                className="nav-link"
              >
                <Button className="market-in-home-button-body">
                  <div className="row">
                    <div className="col-2 text-start p-0 m-0">
                      <img src={state.currency.details[row.base_currency]?.image} alt="logo-crypto" height={35} />
                    </div>
                    <div className="col-2 text-end">
                      <h6 style={{ fontSize: 16, padding: 0, margin: 0 }}>{row.base_currency}</h6>
                      <h6 style={{ fontSize: 12, padding: 0, margin: 0 }}>USDT</h6>
                    </div>
                    <div className="col-3 text-end">
                      <h6 className="mt-2">{row.last_price}</h6>
                    </div>
                    <div className={`col-3 text-end ${((row.last_price - row.first_price) / row.first_price) * 100 < 0 ? "textdanger" : "textsuccess"
                      }`}>
                      <h6 style={{ fontSize: 12 }} className="mt-2">{((row.last_price - row.first_price) / row.first_price) * 100 < 0 ? (
                        <ArrowDownwardIcon className="arrow-table-market" />
                      ) : (
                        <ArrowUpwardIcon className="arrow-table-market" />
                      )}
                        {(((row.last_price - row.first_price) / row.first_price) * 100).toFixed(2)}</h6>
                    </div>
                    <div className="col-2 text-end">
                      <Button className="market-table-btn2" >معامله</Button>
                    </div>
                  </div>
                </Button>
              </NavLink>
            </div>
          </div>))}
    </>
  );
}
