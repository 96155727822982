import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/splide.min.css";
import "./priceslider.scss";
import { useContext } from "react";
import { UserContext } from "../../contexts/providers/user";

export default function NarrowPriceBox(props) {
  const { state } = useContext(UserContext);
  return (
    <div className="slider-main">
      <Splide
        options={{
          type: "loop",
          gap: "1px",
          drag: "free",
          arrows: false,
          pagination: false,
          focus: "center",
          updateOnMove: true,
          breakpoints: {
            640: {
              perPage: 1,
            },
            768: {
              perPage: 2,
            },
            868: {
              perPage: 3,
            },
            1024: {
              perPage: 3,
            },
            1385: {
              perPage: 3,
            },
            1440: {
              perPage: 6,
            },
            2000: {
              perPage: 6,
            },
          },
          direction: "rtl",
          autoScroll: {
            pauseOnHover: false,
            pauseOnFocus: false,
            rewind: false,
            speed: 2,
          },
        }}
        extensions={{ AutoScroll }}
      >
        {props.name
          .sort((a, b) => a.base_currency.localeCompare(b.base_currency))
          .map((itemhere) => {
            return (
              <SplideSlide>
                <div className="d-flex p-2" style={{ direction: "ltr" }}>
                  <img
                    className="me-1"
                    src={state.currency.details[itemhere.base_currency]?.image}
                    style={{
                      width: 25,
                      height: 25,
                      marginLeft: 10,
                      marginRight: -5,
                    }}
                    alt={"currency"}
                  />
                  <div className="me-1">
                    {itemhere.trading_pairs.replace("_", "/")}
                  </div>
                  <div className="me-3">{itemhere.last_price}</div>
                  <div
                    className={`${((itemhere.last_price - itemhere.first_price) / itemhere.first_price) * 100 < 0 ? "textdanger" : "textsuccess"
                      }`}
                    align="right"
                    style={{ direction: "ltr" }}
                  >
                    ({(((itemhere.last_price - itemhere.first_price) / itemhere.first_price) * 100).toFixed(2)}%)
                  </div>
                </div>
              </SplideSlide>
            );
          })}
      </Splide>
    </div>
  );
}
