import "./marketinhome.scss";
import { Button } from "react-bootstrap";
import { useEffect } from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getSummary } from "../../utils/Api";
import { RotatingLines } from "react-loader-spinner";
import { Link, NavLink } from "react-router-dom";
import { UserContext } from "../../contexts/providers/user";
import { useContext } from "react";
import NarrowPriceBox from "../../components/priceSlider/PriceSlider";
import ArrowDownwardIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import ArrowUpwardIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

const homeCurrencies = ["AAVE", "BTC", "ETH", "ADA", "BNB", "TRX"];

export default function MarketInHome() {
  const { state } = useContext(UserContext);
  const [summaryData, setSummaryData] = useState(null);

  const errorCatch = (error) => { };

  useEffect(() => {
    getSummary(setSummaryData, errorCatch);
  }, []);
  if (!summaryData) {
    return (
      <div className="container-fluid rotating-loader-lines">
        <div className="row justify-content-center">
          <div className="col text-center">
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="35"
              visible={true}
            />
          </div>
        </div>
      </div>
    );
  }


  return (
    <>
      <div className="container-fluid priceBox">
        {summaryData && <NarrowPriceBox name={summaryData} />}
      </div>
      <TableContainer component={Paper} className="table-main">
        <Table sx={{ minWidth: 600 }} aria-label="simple table dark">
          <TableHead className="table-head">
            <TableRow className="table-row">
              <TableCell className="table-row" align="center" >
                جفت ارز
              </TableCell>
              <TableCell className="table-row" align="right">
                آخرین
              </TableCell>
              <TableCell className="table-row" align="right">
                بالاترین   (24h)
              </TableCell>
              <TableCell className="table-row" align="right">
                پایین ترین   (24h)
              </TableCell>
              <TableCell className="table-row" align="right">
                تغییرات   (24h)
              </TableCell>
              <TableCell className="table-row" align="right">
                حجم (24h)
              </TableCell>
              <TableCell className="table-row" align="right">

              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {summaryData
              .filter((item) => {
                return homeCurrencies.includes(item.base_currency);
              })
              .sort((a, b) => a.base_currency.localeCompare(b.base_currency))
              .map((row) => (
                <TableRow
                  className="table-row"
                  key={row.base_currency}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell className="table-row" align="right">
                    <div className="row">
                      <div className="col-2">
                        <img
                          src={state.currency.details[row.base_currency].image}
                          style={{
                            width: 25,
                            height: 25,
                            marginLeft: 10,
                            marginRight: 5,
                            marginBottom: 5,
                          }}
                          alt={"currency"}
                        />
                      </div>
                      <div className="col-7 mt-1 me-1 ms-1">
                        {row.trading_pairs.replace("_", "/")}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell className="table-row" align="right">
                    {row.last_price}
                  </TableCell>
                  <TableCell className="table-row" align="right">
                    {row.highest_price_24h}
                  </TableCell>
                  <TableCell className="table-row" align="right">
                    {row.lowest_price_24h}
                  </TableCell>
                  <TableCell
                    className={`${((row.last_price - row.first_price) / row.first_price) * 100 < 0 ? "textdanger" : "textsuccess"
                      }`}
                    align="right"
                    style={{ direction: "ltr" }}
                  >
                    {((row.last_price - row.first_price) / row.first_price) * 100 < 0 ? (
                      <ArrowDownwardIcon className="arrow-table-market" />
                    ) : (
                      <ArrowUpwardIcon className="arrow-table-market" />
                    )}
                    {(((row.last_price - row.first_price) / row.first_price) * 100).toFixed(2)}
                  </TableCell>
                  <TableCell className="table-row" align="right">
                    {parseFloat(`${row.quote_volume}`).toFixed(2)}
                  </TableCell>
                  <TableCell className="table-row" align="right">
                    <NavLink
                      as={Link}
                      to={`/trade/${row.trading_pairs}`}
                      className="nav-link"
                    >
                      <Button className="market-table-btn" >معامله</Button>
                    </NavLink>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
