import { Button } from "react-bootstrap";
import { useContext, useEffect } from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { RotatingLines } from "react-loader-spinner";
import { getSummary } from "../../../utils/Api";
import { Link, NavLink } from "react-router-dom";
import { UserContext } from "../../../contexts/providers/user";
import ArrowDownwardIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import ArrowUpwardIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { Input } from "@mui/material";

export default function MarketTable() {
  const { state } = useContext(UserContext);
  const [summaryData, setSummaryData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const errorCatch = (error) => { };

  useEffect(() => {
    getSummary(setSummaryData, errorCatch);
  }, []);

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value.toUpperCase());
  };

  const filteredData = summaryData
    ? summaryData.filter((row) =>
      row.trading_pairs.includes(searchQuery)
    )
    : [];

  if (!summaryData) {
    return (
      <div className="container-fluid rotating-loader-lines">
        <div className="row justify-content-center">
          <div className="col text-center">
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="35"
              visible={true}
            />
          </div>
        </div>
      </div>
    );
  }


  return (
    <div className="search-main">

      <Input
        type="text"
        placeholder="جستجو"
        value={searchQuery}
        onChange={handleInputChange}
        className="searchbar"
        style={{ direction: 'ltr', textAlign: 'left' }}
      />
      <div className="table-container-wrapper">
        <TableContainer component={Paper} className="table-main">
          <Table sx={{ minWidth: 800 }} aria-label="simple table dark">
            <TableHead className="table-head">
              <TableRow className="table-row">
                <TableCell className="table-row" align="center" width={115}>
                  جفت ارز
                </TableCell>
                <TableCell className="table-row" align="right" width={90}>
                  آخرین قیمت
                </TableCell>
                <TableCell className="table-row" align="right" width={90}>
                  بالاترین  (24h)
                </TableCell>
                <TableCell className="table-row" align="right" width={90}>
                  پایین ترین  (24h)
                </TableCell>
                <TableCell className="table-row" align="right" width={90}>
                  تغییرات  (24h)
                </TableCell>
                <TableCell className="table-row" align="right" width={90}>
                  حجم (24h)
                </TableCell>
                <TableCell className="table-row" align="right" width={100}>

                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .sort((a, b) => a.base_currency.localeCompare(b.base_currency))
                .map((row) => (
                  <TableRow
                    className="table-row"
                    key={row.base_currency}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      className="table-row"
                      component="th"
                      scope="row"
                      align="right"
                    >
                      <div className="row">
                        <div className="col-2">
                          <img
                            src={state.currency.details[row.base_currency]?.image}
                            style={{
                              width: 30,
                              height: 30,
                              marginLeft: 35,
                              marginRight: -4,
                              marginBottom: 5,
                            }}
                            alt={"currency"}
                          />
                        </div>
                        <div className="col-7" style={{
                          marginLeft: 5,
                          marginRight: 4,
                          marginTop: 5
                        }}>
                          {row.trading_pairs.replace("_", "/")}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell className="table-row" align="right">
                      {row.last_price}
                    </TableCell>
                    <TableCell className="table-row" align="right">
                      {row.highest_price_24h}
                    </TableCell>
                    <TableCell className="table-row" align="right">
                      {row.lowest_price_24h}
                    </TableCell>
                    <TableCell
                      className={`${((row.last_price - row.first_price) / row.first_price) * 100 < 0 ? "textdanger" : "textsuccess"
                        }`}
                      align="right"
                      style={{ direction: "ltr" }}
                    >
                      {((row.last_price - row.first_price) / row.first_price) * 100 < 0 ? (
                        <ArrowDownwardIcon className="arrow-table-market" />
                      ) : (
                        <ArrowUpwardIcon className="arrow-table-market" />
                      )}
                      {(((row.last_price - row.first_price) / row.first_price) * 100).toFixed(2)}
                    </TableCell>
                    <TableCell className="table-row" align="right">
                      {parseFloat(row.quote_volume).toFixed(2)}
                    </TableCell>
                    <TableCell className="table-row" align="right">
                      <NavLink
                        as={Link}
                        to={`/trade/${row.trading_pairs}`}
                        className="nav-link"
                      >
                        <Button className="market-table-btn">معامله</Button>
                      </NavLink>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
