import * as React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./assets.scss";
import "./assets.scss";
import { UserContext } from "../../contexts/providers/user";
import { useContext } from "react";
import Paper from "@mui/material/Paper";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { toFixed } from "../../utils/math";
import { ItemNames, getStorage } from "../../utils/RSS";
import { createApiAddress } from "../../constant/api.constant";
import { Link, useNavigate } from "react-router-dom";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: ` e-tab-${index}`,
    "aria-controls": ` tabpanel-${index}`,
  };
}

export default function Wallets() {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [bitcoinPrice, setBitcoinPrice] = useState(0);
  const [irtPrice, setIrtPrice] = useState(0);
  const [walletDatas, setWalletDatas] = useState([]);
  const { state, dispatch } = useContext(UserContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery2, setSearchQuery2] = useState("");
  const [searchQuery3, setSearchQuery3] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(walletDatas).length === 0) {
      fetch(createApiAddress("trade.user_wallet"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
        },
      })
        .then(async (response) => {
          if (response.status === 401) {
            dispatch({ type: "Logout" })
            return;
          }
          if (response.status === 503) {
            navigate('/maintenance')
            return;
          }
          const data = await response.json();
          let pricesResponse = await fetch(createApiAddress("trade.prices"));
          let prices = await pricesResponse.json();
          let wallets = {};
          setBitcoinPrice(prices["BTC"].USDT);
          setIrtPrice(prices["USDT"].IRT);
          Object.keys(data.data).map((item, index) => {
            wallets[item] = {
              ...data.data[item],
              ...prices[item],
            };
            return true;
          });
          setWalletDatas(wallets);
        })
        .catch((error) => console.log(''));
    }
  }, [walletDatas]);


  const mainAllAssetsUSDT = Object.keys(walletDatas).reduce((acc, item) => {
    if (item !== "IRT") {
      const totalAmount2USDT = walletDatas[item].main * walletDatas[item].USDT;
      return acc + totalAmount2USDT;
    }
    const totalAmount2USDT = walletDatas[item].main / irtPrice;
    return acc + totalAmount2USDT;
  }, 0);

  const tradeAllAssetsUSDT = Object.keys(walletDatas).reduce((acc, item) => {
    if (item !== "IRT") {
      const totalAmount2USDT = walletDatas[item].trading * walletDatas[item].USDT;
      return acc + totalAmount2USDT;
    }
    const totalAmount2USDT = walletDatas[item].trading / irtPrice;
    return acc + totalAmount2USDT;
  }, 0);

  const userBTCinMain = mainAllAssetsUSDT / bitcoinPrice;
  const userBTCinTrade = tradeAllAssetsUSDT / bitcoinPrice;

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value.toUpperCase());
  };

  const handleInputChange2 = (e) => {
    setSearchQuery2(e.target.value.toUpperCase());
  };

  const handleInputChange3 = (e) => {
    setSearchQuery3(e.target.value.toUpperCase());
  };

  const filteredData = Object.keys(state.currency.details)
    .sort((a, b) => {
      let itema = state.currency.details[a];
      let itemb = state.currency.details[b];
      let amountA = parseFloat(
        `${walletDatas[itema.symbol]?.main +
        walletDatas[itema.symbol]?.trading || 0
        }`
      );
      let amountB = parseFloat(
        `${walletDatas[itemb.symbol]?.main +
        walletDatas[itemb.symbol]?.trading || 0
        }`
      );
      if (amountA > amountB) {
        return -1;
      } else if (amountA < amountB) {
        return 1;
      }
      return 0;
    })
    .filter((item1) => {
      const item = state.currency.details[item1];
      const symbolIncludesQuery = item.symbol.toLowerCase().includes(searchQuery.toLowerCase());
      const nameIncludesQuery = item.name.toLowerCase().includes(searchQuery.toLowerCase());
      return symbolIncludesQuery || nameIncludesQuery;
    });

  const filteredData2 = Object.keys(state.currency.details)
    .sort((a, b) => {
      let itema = state.currency.details[a];
      let itemb = state.currency.details[b];
      let amountA = parseFloat(
        `${walletDatas[itema.symbol]?.main || 0}`
      );
      let amountB = parseFloat(
        `${walletDatas[itemb.symbol]?.main || 0}`
      );
      if (amountA > amountB) {
        return -1;
      } else if (amountA < amountB) {
        return 1;
      }
      return 0;
    })
    .filter((item1) => {
      const item = state.currency.details[item1];
      const symbolIncludesQuery = item.symbol.toLowerCase().includes(searchQuery2.toLowerCase());
      const nameIncludesQuery = item.name.toLowerCase().includes(searchQuery2.toLowerCase());
      return symbolIncludesQuery || nameIncludesQuery;
    });

  const filteredData3 = Object.keys(state.currency.details)
    .sort((a, b) => {
      let itema = state.currency.details[a];
      let itemb = state.currency.details[b];
      let amountA = parseFloat(
        `${walletDatas[itema.symbol]?.trading || 0}`
      );
      let amountB = parseFloat(
        `${walletDatas[itemb.symbol]?.trading || 0}`
      );
      if (amountA > amountB) {
        return -1;
      } else if (amountA < amountB) {
        return 1;
      }
      return 0;
    })
    .filter((item1) => {
      const item = state.currency.details[item1];
      const symbolIncludesQuery = item.symbol.toLowerCase().includes(searchQuery3.toLowerCase());
      const nameIncludesQuery = item.name.toLowerCase().includes(searchQuery3.toLowerCase());
      return symbolIncludesQuery || nameIncludesQuery;
    });

  const addCommasToNumber = (item) => {
    let number;
    if (item.symbol !== "IRT") {
      number = parseFloat(
        `${(walletDatas[item.symbol]?.USDT || 0) *
        (walletDatas[item.symbol]?.main + walletDatas[item.symbol]?.trading || 0)
        }`
      );
    } else {
      number = parseFloat(
        `${(walletDatas[item.symbol]?.main + walletDatas[item.symbol]?.trading || 0) / irtPrice
        }`
      );
    }
    number = number.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return number;
  };

  const addCommasToNumber2 = (item) => {
    let number =
      parseFloat(
        `${walletDatas[item.symbol]?.main + walletDatas[item.symbol]?.trading || 0}`)
    if (item.symbol === "IRT") {
      number = number.toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    } else {
      number = number.toLocaleString("en-US", {
        minimumFractionDigits: 6,
        maximumFractionDigits: 6,
      });
    }
    return number;
  };

  const addCommasToNumber3 = (item) => {
    let number = parseFloat(`${walletDatas[item.symbol]?.main || 0}`)
    if (item.symbol === "IRT") {
      number = number.toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    } else {
      number = number.toLocaleString("en-US", {
        minimumFractionDigits: 6,
        maximumFractionDigits: 6,
      });
    }
    return number;
  };

  const addCommasToNumber4 = (item) => {
    let number;
    if (item.symbol !== "IRT") {
      number = parseFloat(`${(walletDatas[item.symbol]?.USDT || 0) * (walletDatas[item.symbol]?.main || 0)}`)
      number = number.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

    } else {
      number = (walletDatas[item.symbol]?.main || 0) / irtPrice
      number = number.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return number;
  };

  const addCommasToNumber5 = (item) => {
    let number = parseFloat(`${walletDatas[item.symbol]?.trading || 0}`)
    if (item.symbol === "IRT") {
      number = number.toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    } else {
      number = number.toLocaleString("en-US", {
        minimumFractionDigits: 6,
        maximumFractionDigits: 6,
      });
    }
    return number;
  };

  const addCommasToNumber6 = (item) => {
    let number;
    if (item.symbol !== "IRT") {
      number = parseFloat(`${(walletDatas[item.symbol]?.USDT || 0) * (walletDatas[item.symbol]?.trading || 0)}`)
      number = number.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

    } else {
      number = (walletDatas[item.symbol]?.trading || 0) / irtPrice
      number = number.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return number;
  };


  return (

    <div className="wallet-scroll">
      <Box sx={{ width: "100%" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs variant="scrollable" value={value} onChange={handleChange}>
                  <Tab label="تمام دارایی ها" {...a11yProps(0)} />
                  <Tab label="کیف پول اصلی" {...a11yProps(1)} />
                  <Tab label="کیف پول معاملاتی" {...a11yProps(2)} />
                </Tabs>
              </Box>
            </div>
          </div>
        </div>
        <TabPanel value={value} index={0}>
          <div className="sumofallassets container-fluid">
            <div className="row justify-content-between">
              <div className="col">
                <p>
                  کل دارایی معادل تتر:{" "}
                  {/* {mainAllAssetsUSDT} */}
                  {(mainAllAssetsUSDT + tradeAllAssetsUSDT).toFixed(2)}
                </p>
                <p>
                  کل دارایی معادل بیتکوین:{" "}

                  {(userBTCinMain + userBTCinTrade).toFixed(5)}
                </p>
                <p>
                  کل دارایی معادل تومان:{" "}
                  {new Intl.NumberFormat("ir-FA", {
                    style: "currency",
                    currency: "irt",
                    minimumFractionDigits: 0,
                  }).format(
                    (
                      (mainAllAssetsUSDT + tradeAllAssetsUSDT).toFixed(2) *
                      irtPrice
                    ).toFixed(0)
                  )}
                </p>
              </div>
              <div className="col">
                <p>نکته:</p>
                <p className="m-3 p-3 text-asset-top">جهت ارتقاء امنیت کاربران و جلوگیری از اشتباه رقمی در سفارش گذاری، لطفاً مقدار رمز ارزی را که قصد معامله دارید ار حساب اصلی به حساب معاملاتی انتقال دهید ( <Link to="/transfer">مراجعه به صفحه انتقال</Link>) و سپس انجام معامله نمایید. توصیه می شود پس از انجام معامله مقدار رمز ارز را از حساب معاملاتی خود به حساب اصلی منتقل نمایید.</p>
              </div>
            </div>
          </div>
          <input
            type="text"
            placeholder="جستجو"
            value={searchQuery}
            onChange={handleInputChange}
            className="searchbar"
          />
          <TableContainer
            component={Paper}
            sx={{ minWidth: 580 }}
            style={{ height: 570 }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left"> نوع ارز</TableCell>
                  <TableCell align="left">مقدار کل</TableCell>
                  <TableCell align="left"> معادل تتر</TableCell>
                  <TableCell align="left"> معادل تومان</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .map((item1, index) => {
                    let item = state.currency.details[item1];
                    return (
                      <TableRow
                        key={`mainWallet${index}`}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">
                          <div className="d-flex">
                            <img
                              src={item.image}
                              style={{
                                width: 25,
                                height: 25,
                                marginLeft: 10,
                                marginTop: 4,
                              }}
                              alt={"currency"}
                            />
                            <div className="d-flex flex-column">
                              <span> {item.symbol}</span>
                              <span style={{ fontSize: "10px" }}>
                                {" "}
                                ({item.name})
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          {addCommasToNumber2(item)}
                        </TableCell>
                        <TableCell align="left">
                          {addCommasToNumber(item)}
                        </TableCell>
                        <TableCell align="left">
                          {new Intl.NumberFormat("ir-FA", {
                            style: "currency",
                            currency: "irt",
                            minimumFractionDigits: 0,
                          }).format(
                            toFixed(
                              parseFloat(
                                `${(walletDatas[item.symbol]?.main +
                                  walletDatas[item.symbol]?.trading || 0) *
                                (walletDatas[item.symbol]?.IRT || 0)
                                }`
                              ),
                              0
                            )
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="sumofallassets">
            <div className="row">
              <div className="col">
                <p>کل دارایی معادل تتر: {mainAllAssetsUSDT.toFixed(2)}</p>
                <p>کل دارایی معادل بیتکوین: {userBTCinMain.toFixed(5)}</p>
                <p>
                  کل دارایی معادل تومان:{" "}
                  {new Intl.NumberFormat("ir-FA", {
                    style: "currency",
                    minimumFractionDigits: 0,
                    currency: "irt",
                  }).format(
                    (mainAllAssetsUSDT.toFixed(2) * irtPrice).toFixed(0)
                  )}
                </p>
              </div>
            </div>
          </div>
          <input
            type="text"
            placeholder="جستجو"
            value={searchQuery2}
            onChange={handleInputChange2}
            className="searchbar"
          />
          <TableContainer
            component={Paper}
            sx={{ minWidth: 580 }}
            style={{ height: 570 }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left"> نوع ارز</TableCell>
                  <TableCell align="left">مقدار کل</TableCell>
                  <TableCell align="left"> معادل تتر</TableCell>
                  <TableCell align="left"> معادل تومان</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  filteredData2.map((item1, index) => {
                    let item = state.currency.details[item1];
                    return (
                      <TableRow
                        key={`mainWallet${index}`}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">
                          <div className="d-flex">
                            <img
                              src={item.image}
                              style={{
                                width: 25,
                                height: 25,
                                marginLeft: 10,
                                marginTop: 4,
                              }}
                              alt={"currency"}
                            />
                            <div className="d-flex flex-column">
                              <span> {item.symbol}</span>
                              <span style={{ fontSize: "10px" }}>
                                {" "}
                                ({item.name})
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          {addCommasToNumber3(item)}

                        </TableCell>
                        <TableCell align="left">
                          {addCommasToNumber4(item)}

                          { }
                        </TableCell>
                        <TableCell align="left">
                          {new Intl.NumberFormat("ir-FA", {
                            style: "currency",
                            currency: "irt",
                            minimumFractionDigits: 0,
                          }).format(
                            toFixed(
                              parseFloat(
                                `${(walletDatas[item.symbol]?.main || 0) *
                                (walletDatas[item.symbol]?.IRT || 0)
                                }`
                              ),
                              0
                            )
                          )}
                        </TableCell>

                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className="sumofallassets">
            <div className="row">
              <div className="col">
                <p>کل دارایی معادل تتر: {tradeAllAssetsUSDT.toFixed(2)}</p>
                <p>کل دارایی معادل بیتکوین: {userBTCinTrade.toFixed(5)}</p>
                <p>
                  کل دارایی معادل تومان:{" "}
                  {new Intl.NumberFormat("ir-FA", {
                    style: "currency",
                    minimumFractionDigits: 0,
                    currency: "irt",
                  }).format(
                    (tradeAllAssetsUSDT.toFixed(2) * irtPrice).toFixed(0)
                  )}
                </p>
              </div>
            </div>
          </div>
          <input
            type="text"
            placeholder="جستجو"
            value={searchQuery3}
            onChange={handleInputChange3}
            className="searchbar"
          />
          <TableContainer
            component={Paper}
            sx={{ minWidth: 580 }}
            style={{ height: 570 }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left"> نوع ارز</TableCell>
                  <TableCell align="left">مقدار کل</TableCell>
                  <TableCell align="left"> معادل تتر</TableCell>
                  <TableCell align="left"> معادل تومان</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  filteredData3.map((item1, index) => {
                    let item = state.currency.details[item1];
                    return (
                      <TableRow
                        key={`mainWallet${index}`}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">
                          <div className="d-flex">
                            <img
                              src={item.image}
                              style={{
                                width: 25,
                                height: 25,
                                marginLeft: 10,
                                marginTop: 4,
                              }}
                              alt={"currency"}
                            />
                            <div className="d-flex flex-column">
                              <span> {item.symbol}</span>
                              <span style={{ fontSize: "10px" }}>
                                {" "}
                                ({item.name})
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          {addCommasToNumber5(item)}

                        </TableCell>
                        <TableCell align="left">
                          {addCommasToNumber6(item)}
                          { }
                        </TableCell>
                        <TableCell align="left">
                          {new Intl.NumberFormat("ir-FA", {
                            style: "currency",
                            currency: "irt",
                            minimumFractionDigits: 0,
                          }).format(
                            toFixed(
                              parseFloat(
                                `${(walletDatas[item.symbol]?.trading || 0) *
                                (walletDatas[item.symbol]?.IRT || 0)
                                }`
                              ),
                              0
                            )
                          )}
                        </TableCell>

                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </Box>
    </div>
  );
}
